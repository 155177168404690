<template>
  <div>
    <Scroll :on-reach-bottom="getList" height="800">
      <br><br>
      <Row type="flex" justify="center">
        <Col :xs="20" :sm="18" :md="12" :lg="12">
          <Card v-if="token">
            <p slot="title">
              <Icon type="ios-create-outline"/>
              说点什么把！
            </p>
            <textarea v-model="moment.Content" style="width:100%;height:100px;"></textarea>
            <br>


            <Icon type="md-status"/>
            图片
            <Divider type="vertical"/>
            <Icon type="ios-navigate-outline"/>
            定位
            <Divider type="vertical"/>

            <Select v-model="moment.Status" style="width:60px">
              <Option :value="1">公开</Option>
              <Option :value="0">保密</Option>
              <Option :value="2">微博</Option>
            </Select>

            <Divider type="vertical"/>

            <Button @click="sendMessage" type="primary">发布</Button>

            <br>

            <div class="demo-upload-list" v-for="(item,index) in uploadList" :key="index">
              <template v-if="item.Status === 'finished'">
                <img :src="item.url">
                <div class="demo-upload-list-cover">
                  <Icon type="md-eye" @click.native="handleView(item.key)"></Icon>
                  <Icon type="md-eye-off" @click.native="handleRemove(item)"></Icon>
                </div>
              </template>
              <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
              </template>
            </div>

            <Upload ref="upload" :show-upload-list="false" :on-success="handleSuccess" :format="['jpg','jpeg','png']"
                    :max-size="5122" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize"
                    :before-upload="handleBeforeUpload" :data="uploadData" multiple
                    type="drag" action="//upload-z2.qiniu.com" style="display: inline-block;width:58px;">
              <div style="width: 58px;height:58px;line-height: 58px;">
                <Icon type="ios-camera" size="20"></Icon>
              </div>
            </Upload>
            <Modal v-model="visible">
              <img :src="'https://files.xsdhy.com/' + imgKey " v-if="visible" style="width: 100%">
              <div slot="footer"></div>
            </Modal>
          </Card>
          <br>
          <Timeline>

            <TimelineItem v-for="item in essayList" :key="item.id">
              <!-- <Avatar slot="dot" src="https://www.xsdhy.com/uploads/avatar.jpg" /> -->
              <Card>
                <p slot="title" v-if="token"></p>
                <div slot="extra" v-if="token">
                  <a @click="lock(item.id)" v-if="item.Status === 1">
                    <Icon type="md-lock"></Icon>
                  </a>
                  <a @click="lock(item.id)" v-if="item.Status === 0">
                    <Icon type="md-unlock"></Icon>
                  </a>
                  <a style="padding-left:0.5em;" @click="del(item.id)">
                    <Icon type="md-trash"></Icon>
                  </a>
                </div>
                <p v-html="item.Content"></p>

                <Row>
                  <Col :xs="24" :sm="12" :md="8" :lg="8" v-for="(image,index) in item.Images" :key="index">
                    <Card :padding="1">
                      <img :src="'https://files.xsdhy.com/'+image" :preview="item.id" :preview-text="item.Content"
                           width="100%">
                    </Card>
                  </Col>
                </Row>
                <br>
                <p v-if="item.Location != ''">
                  <Icon type="ios-navigate"></Icon>
                  {{ item.Location }}
                </p>
                <!-- <Time :time="item.created_at" /> -->
                <Tag>{{ item.CreatedAt }}</Tag>
              </Card>
            </TimelineItem>

          </Timeline>
        </Col>
      </Row>

    </Scroll>
  </div>
</template>

<style>
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>

<script>
export default {
  data() {
    return {
      token: false,

      essayList: [],
      current_page: 0,
      moment: {
        Status: 1,
        Content: '',
        Location: '',
        Images: []
      },
      uploadData: {
        token: '',
        key: ''
      },

      imgKey: '',
      visible: false,
      uploadList: [],
    }
  },
  created() {
    document.title = "旧梦沧桑 - 消逝的红叶-我就是我，是不一样的烟火";
    this.token = localStorage.getItem("token");
    this.getList();
    if (this.token) {
      this.getToken();
    }
  },
  methods: {
    getList() {
      this.current_page++;
      this.$http.get('/moments?page=' + this.current_page).then((response) => {
        this.essayList = this.essayList.concat(response.data.data);
      }, function () {
        //
      });
    },
    changePage(page) {
      this.nowPage = page;
      this.getList();
    },
    //提交保存
    sendMessage() {
      for (var i = 0; i < this.uploadList.length; i++) {
        this.moment.Images.push(this.uploadList[i].key)
      }
      console.log(this.moment);
      this.$http.post('/moment', this.moment).then((response) => {
        if (200 === response.data.code) {
          this.moment = {
            Status: 1,
            Content: '',
            Location: '',
            Images: []
          };
          this.uploadList = [];
          this.$refs.upload.clearFiles();
          this.current_page = 1;
          this.essayList = [];
          this.getList();
        } else {
          console.log(response.data.msg);
        }
      }, function () {
        //http状态码
      });
    },
    del(id) {
      this.$http.delete('/moment/' + id).then((response) => {
        if (200 === response.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '修改成功'
          });
          this.essayList = [];
          this.getList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: response.data.msg
          });
        }
      }, () => {
        this.$Notice.error({
          title: '操作失败',
          desc: '网络错误'
        });
      });
    },
    lock(id) {
      this.$http.patch('/moment/' + id + '/Status').then((response) => {
        if (200 === response.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '修改成功'
          });
          this.essayList = [];
          this.getList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: response.data.msg
          });
        }
      }, () => {
        this.$Notice.error({
          title: '操作失败',
          desc: '网络错误'
        });
      });
    },
    //上传控件-获取七牛token
    getToken() {
      this.$http.get('/qin').then( (response) => {
        this.uploadData.token = response.data.data;
      });
    },
    //上传控件-点击图片显示大图
    handleView(key) {
      this.imgKey = key;
      this.visible = true;
    },
    //上传控件-移除图片
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    },
    //上传控件-上传成功回调:res为服务器返回,file为文件
    handleSuccess(res, file) {
      this.moment.Images.push();
      file.url = 'https://files.xsdhy.com/' + res.key;
      file.key = res.key;
      // file.name = file.name;
    },
    //上传控件-上传错误格式钩子
    handleFormatError(file) {
      this.$Notice.warning({
        title: 'The file format is incorrect',
        desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
      });
    },
    //上传控件-超过大小限制
    handleMaxSize(file) {
      this.$Notice.warning({
        title: 'Exceeding file size limit',
        desc: 'File  ' + file.name + ' is too large, no more than 2M.'
      });
    },
    //上传控件-上传前回调
    handleBeforeUpload(file) {
      console.log(file)
      const uuidV1 = require('uuid/v1');
      this.uploadData.key = 'moment/w-' + uuidV1().replace(/-/g, "");
      return true;
    },
  },
  mounted() {
    if (this.token) {
      this.uploadList = this.$refs.upload.fileList;
    }

  }
}
</script>
